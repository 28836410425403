import React from 'react';
import { Link } from 'react-router-dom';

import './NotFound.scss';

const NotFound = () => (
  <div className="NotFound">
    <h1>Page Not Found</h1>
    <p>Sorry, this page does not exist</p>

    <Link to="/">
      <button type="button" className="not-found-btn">Back to civilization</button>
    </Link>
  </div>
);

export default NotFound;
