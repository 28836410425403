import React, {useEffect,useRef, useState} from 'react';
import { Link } from 'react-router-dom';
// import './CreateReviews.scss';
import * as Icon from 'react-feather';
import { withAlert } from "react-alert";
import { Button, Form } from 'react-bootstrap';
import { FilePond } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import './Practitioner.scss'
import useForm from '../hooks/useForm';

import http from '../utils/http';
import environment from '../utils/environment';
import Spinner from './Spinner.jsx';

import SelectList from './reusable/select_list/SelectList'
import DatePicker from "react-datepicker";
import TextAreaField from './reusable/text_area_field/TextAreaField'; 

import "react-datepicker/dist/react-datepicker.css";
 

  const reviewRatingOptions = [
    { label: '* Select', value: 0 },
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
  ];

const AddReview = (props) => {

    const { profileId, userType, getProfile, showPage } = props;
    
    console.log(props);
   
    const onSubmit = async (inputs) => {
        try {
          if(!inputs.rating || !inputs.review || !reviewDate) {
            props.alert.error("Must fill in all fields for review!");
            return;
          }

          props.alert.info("Updating...");

          const reviewData = {
            profileId,
            type: "static",
            rating: inputs.rating,
            review: inputs.review,
            date: reviewDate
          }

        let response;

        if (userType == "Sole") {
            response = await http.post(`${environment.resolveApi().rest}/admin/create/singleReview/sole`, reviewData);
        }


        if (userType == "Firm") {
            response = await http.post(`${environment.resolveApi().rest}/admin/create/singleReview/firm`, reviewData);
        }


        if (userType == "Employee") {
            response = await http.post(`${environment.resolveApi().rest}/admin/create/singleReview/employee`, reviewData);
        }

        if (response) {
            props.alert.success("Updated Reviews!");
            if(getProfile) {
                getProfile();
            }

            if(showPage) {
                showPage(false);
            }
        }

        } catch (error) {
            console.log(error);
        }
    }

    const { inputs, setInputs, handleInputChange, handleSubmit } = useForm(onSubmit);
    const [reviewDate, setReviewDate] = useState("");

  return (
    <div className="Practitioner">
    

  
    <div>
        <div style={{display: 'flex', flexFlow: 'column'}}>
            <h4 className="cc-txt-dark">Add Review</h4>
            <p className="cc-txt-dark">Side Note: Make sure the review you are adding has a later date the current reviews this profile has.</p>
        </div>
          

      <Form onSubmit={handleSubmit} className="add-review-form-container">
        
        <div className="cc-flex-row">
            <SelectList
                title="Rating"
                placeholder=""
                name="rating"
                value={inputs.rating}
                onChange={handleInputChange}
                options={reviewRatingOptions}
                info=""
                className="law-portal-select-list-input"
                lawPortal
            />
            
            <div className="cc-flex-column" style={{marginLeft: '20px'}}>
                <span className="lw-portal-select-list-title">Date</span>
                <DatePicker
                    className="date-picker-input law-portal-select-list-input"
                    selected={reviewDate}
                    onChange={setReviewDate}
                />
            </div>
        </div>
        <TextAreaField
            className="modal-text-area-field text-field-msg"
            title="Review"
            placeholder=""
            name="review"
            type="text"
            value={inputs.review}
            onChange={handleInputChange}
            autoComplete="off"
        />

    
   

        

        <Button type="submit" variant="danger" style={{width: '180px', height: '50px', marginTop: '40px'}} >Submit</Button>

      </Form>
    </div>
   


    </div>
  );
};


export default withAlert()(AddReview);


