import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
// import "./CreateReviews.scss";
import * as Icon from "react-feather";
import { withAlert } from "react-alert";
import http from "../../../utils/http";

import { Button, Form, Spinner } from "react-bootstrap";
import { FilePond } from "react-filepond";

import "react-datepicker/dist/react-datepicker.css";
import useForm from "../../../hooks/useForm";
import environment from "../../../utils/environment";
import "./SoleEditProfile.scss";

const SoleEditProfile = (props) => {
  let { profile } = props;

  const [picUrl, setPictureUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [isConverted, setIsConverted] = useState(false);
  const uploadRef = useRef(null);

  const { inputs, setInputs, handleInputChange, handleSubmit } = useForm();

  const onImageClick = useCallback(() => {
    uploadRef.current.click();
  }, [uploadRef]);

  const onFileUpload = async (e) => {
    e.persist();

    const file = e.target.files[0];

    //  If the file size (calculated in byte form) is than 1MB
    if (file.size > 1.0e6) {
      return props.alert.error("IMAGE SIZE MUST BE LESS THAN 1MB");
    }
    if (file.type !== "image/png" && file.type !== "image/jpeg") {
      return props.alert.error("IMAGE TYPE MUST BE PNG OR JPG");
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);

      formData.append("id", profile._id);

      const response = await http.uploadImage(
        `${environment.resolveApi().rest}/admin/sole_prac/upload`,
        formData
      );
      setLoading(false);
      setPictureUrl(response.data.pictureURL);

      console.log(response.data.pictureURL);
    } catch (error) {
      console.error(error);
    }
  };

  const convertToFirm = async () => {
    try {
      const response = await http.post(
        `${environment.resolveApi().rest}/admin/sole_prac/convert_to_firm`,
        { id: profile._id }
      );

      console.log("response", response);

      await isConvertedAlready();
    } catch (error) {
      console.log(error);
    }
  };

  const isConvertedAlready = async () => {
    try {
      console.log(profile);
      console.log("profile.userId", profile.userId);
      const response = await http.post(
        `${environment.resolveApi().rest}/admin/sole_prac/is_converted`,
        { userId: profile.userId }
      );

      setIsConverted(response.data);

      console.log("response", response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    console.log("profile", profile);
    setInputs(profile);
    isConvertedAlready();
    setLoading(false);
  }, []);

  useEffect(() => {}, []);

  if (loading) return <Spinner />;

  return (
    <div className="FirmEditProfile">
      {isConverted ? (
        <span>
          profile already converted to law firm account. Pls, refer to law firms
          tab
        </span>
      ) : (
        <>
          <div
            className={"new-lawfirm-modal-top-w-profile-pic-w"}
            onClick={onImageClick}
          >
            <img
              className="new-lawfirm-uploaded-pic"
              src={picUrl ? picUrl : inputs.pictureURL}
              alt=""
            />
            <input type="file" onChange={onFileUpload} ref={uploadRef} />
          </div>

          <div className="firmEditProfile__labelWrap">
            <span className="firmEditProfile__label">
              Status: Sole Practitioner
            </span>
            <button
              className="soleEditProfile__convertBtn"
              onClick={() => {
                convertToFirm();
              }}
            >
              Convert to law firm
            </button>{" "}
          </div>
        </>
      )}
    </div>
  );
};

export default withAlert()(SoleEditProfile);
