import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import "./CreateReviews.scss";
import * as Icon from "react-feather";
import { withAlert } from "react-alert";
import http from "../utils/http";
import environment from "../utils/environment";
import Spinner from "./Spinner.jsx";
import { Button, Form } from "react-bootstrap";
import { FilePond } from "react-filepond";
import useForm from "../hooks/useForm";

import SelectList from "./reusable/select_list/SelectList";
import DatePicker from "react-datepicker";
import TextAreaField from "./reusable/text_area_field/TextAreaField";

import "react-datepicker/dist/react-datepicker.css";
import AddReview from "./AddReview.jsx";
import FirmEditProfile from "./admin_edit_profile/firm_edit_profile/FirmEditProfile";

const ccTrustedOptions = [
  { label: "* Select", value: 0 },
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

const Employees = (props) => {
  const onSubmit = async (inputs) => {
    try {
      if (!inputs.ccTrusted) {
        props.alert.error("Must fill in all fields!");
        return;
      }
      const response = await http.post(
        `${environment.resolveApi().rest}/admin/firm/profile/update`,
        { firmId: firmProfile._id, ccTrusted: inputs.ccTrusted }
      );
      props.alert.success("Updated Profile!");
      setEditProfile(false);
      getFirmProfile();
    } catch (error) {
      console.log(error);
    }
  };

  if (!props.location.state) {
    props.history.push("/");
  } else {
    var lawFirmInfo = props.location.state.state;
  }
  //
  const [files, setFiles] = useState([]);

  const [list, setList] = useState([]);
  const [firmProfile, setFirmProfile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const [showImportRev, setImportRev] = useState(false);
  const [showEditProfile, setEditProfile] = useState(false);
  const [showEmployees, setEmployeesShow] = useState(false);
  const [showAddRev, setAddRev] = useState(false);

  const [refreshProfile, setRefreshProfile] = useState(false);

  const { inputs, setInputs, handleInputChange, handleSubmit } =
    useForm(onSubmit);

  const onSearch = (event) => {
    setSearchInput(event.target.value);
  };

  const getEmployeeProfiles = async () => {
    try {
      const response = await http.post(
        `${environment.resolveApi().rest}/admin/list/employees`,
        { lawFirmId: lawFirmInfo._id }
      );
      setList(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getFirmProfile = async () => {
    try {
      const response = await http.post(
        `${environment.resolveApi().rest}/admin/firm`,
        { lawFirmId: lawFirmInfo._id }
      );
      setFirmProfile(response.data[0]);
      setInputs(response.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const uploadRef = useRef(null);

  const onFileUpload = async (fileList) => {
    try {
      props.alert.info("Loading...");

      let file = fileList[0];
      const formData = new FormData();
      formData.append("file", file);

      const response = await http.post(
        `${environment.resolveApi().rest}/admin/upload/file`,
        formData
      );
      console.log("file upload", response);
      const response2 = await http.post(
        `${environment.resolveApi().rest}/admin/create/review/firm`,
        { lawFirmId: firmProfile._id, csvFileUrl: response.data }
      );
      console.log("file import response", response2);
      props.alert.success("Reviews Updated Successfully!");
      setImportRev(false);

      getFirmProfile();
    } catch (error) {
      console.log(error);
      if (error.file) {
        props.alert.error(error.file);
      }
      if (error.type) {
        props.alert.error(error.type);
      }
      if (error.size) {
        props.alert.error(error.size);
      }
      console.error(error);
    }
  };

  const createBestReview = async () => {
    try {
      const response = await http.post(
        `${environment.resolveApi().rest}/admin/bestReview/firm`,
        { profileId: firmProfile._id }
      );

      console.log("best review added!");

      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    getEmployeeProfiles();
    getFirmProfile();
    setLoading(false);
  }, []);

  if (loading) return <Spinner />;

  return (
    <div className="CreateSignupLink SolePractitioners">
      <div className="cc-flex-row" style={{ alignItems: "center" }}>
        <div style={{ display: "flex", flexFlow: "column" }}>
          <div className="cc-flex-row">
            <Icon.ArrowLeftCircle
              onClick={() => {
                props.history.push("/law-firms");
              }}
              className="back-btn"
              size={50}
              strokeWidth={2}
              color={"#D01E1B"}
              style={{ marginRight: "20px" }}
            />
            <div style={{ display: "flex", flexFlow: "column" }}>
              <h3 className="cc-txt-dark">{firmProfile.name}</h3>

              <strong className="cc-txt-dark">
                {firmProfile.city} - Reviews:{" "}
                {firmProfile.reviews ? firmProfile.reviews.length : ""}
              </strong>
            </div>
          </div>
        </div>
        {/* <Button variant="danger" href={`https://app.counselconnect.ca/profile/${profile.handle}`} target="_blank" style={{width: '180px',marginLeft: '50px'}}>View Social Profile</Button> */}

        <Button
          variant="danger"
          style={{ marginLeft: "30px" }}
          onClick={() => {
            setEmployeesShow(true);
            setEditProfile(false);
            setImportRev(false);
            setAddRev(false);
          }}
        >
          <Icon.Users
            className="phone-icon"
            size={20}
            strokeWidth={2}
            color={"#FFFFFF"}
            style={{ marginRight: "5px" }}
          />
          View Employees
        </Button>

        <Button
          variant="dark"
          style={{ marginLeft: "30px" }}
          onClick={() => {
            setEditProfile(true);
            setImportRev(false);
            setAddRev(false);
            setEmployeesShow(false);
          }}
        >
          <Icon.Edit
            className="phone-icon"
            size={20}
            strokeWidth={2}
            color={"#FFFFFF"}
            style={{ marginRight: "5px" }}
          />
          Edit Profile
        </Button>

        <Button
          variant="dark"
          style={{ marginLeft: "30px" }}
          onClick={() => {
            setAddRev(true);
            setImportRev(false);
            setEditProfile(false);
            setEmployeesShow(false);
          }}
        >
          <Icon.PlusCircle
            className="phone-icon"
            size={20}
            strokeWidth={2}
            color={"#FFFFFF"}
            style={{ marginRight: "5px" }}
          />
          Add Review
        </Button>

        <Button
          variant="primary"
          style={{ marginLeft: "30px" }}
          onClick={() => {
            createBestReview();

            // setAddRev(true);
            // setImportRev(false);
            // setEditProfile(false);
            // setEmployeesShow(false);
          }}
        >
          <Icon.PlusCircle
            className="phone-icon"
            size={20}
            strokeWidth={2}
            color={"#FFF"}
            style={{ marginRight: "5px" }}
          />
          Best Review
        </Button>

        <Button
          variant="dark"
          style={{ marginLeft: "30px" }}
          onClick={() => {
            setImportRev(true);
            setEditProfile(false);
            setAddRev(false);
            setEmployeesShow(false);
          }}
        >
          <Icon.Star
            className="phone-icon"
            size={20}
            strokeWidth={2}
            color={"#FFFFFF"}
            style={{ marginRight: "5px" }}
          />
          Import Reviews
        </Button>
      </div>

      <hr style={{ width: "100%", marginTop: "30px" }}></hr>

      {showAddRev && (
        <AddReview
          profileId={firmProfile._id}
          userType="Firm"
          getProfile={getFirmProfile}
          showPage={setAddRev}
          {...props}
        />
      )}

      {showEditProfile && !showImportRev && !showEmployees && (
        <div>
          <h3 className="cc-txt-dark">Edit Profile</h3>

          <FirmEditProfile
            profile={firmProfile}
            userType="Firm"
            refreshProfile={() => {
              getFirmProfile();
            }}
          />

          {/* 
          <Form onSubmit={handleSubmit}>
            <SelectList
              title="CC Trusted"
              placeholder=""
              name="ccTrusted"
              value={inputs.cc_trusted}
              onChange={handleInputChange}
              options={ccTrustedOptions}
              info=""
              className="law-portal-select-list-input"
              lawPortal
            />

            <Button
              type="submit"
              variant="danger"
              style={{ width: "180px", height: "50px" }}
            >
              Submit
            </Button>
          </Form> */}
        </div>
      )}

      {showEmployees && !showImportRev && !showEditProfile && (
        <div style={{ width: "100%" }}>
          <div className="users-list-search-add-wrap">
            <h2 className="cc-txt-dark">Employees</h2>
            <input
              className="users-search-field"
              type="search"
              placeholder="Search Name..."
              autoComplete="off"
              onChange={onSearch}
            ></input>
          </div>

          <table className="table" style={{ backgroundColor: "white" }}>
            <thead id="table-header">
              <tr>
                <th>
                  <Icon.User
                    className="phone-icon"
                    size={20}
                    strokeWidth={2}
                    color={"#412224"}
                    style={{ marginRight: "5px" }}
                  />
                  Name
                </th>
                <th>
                  <Icon.Phone
                    className="phone-icon"
                    size={20}
                    strokeWidth={2}
                    color={"#412224"}
                    style={{ marginRight: "5px" }}
                  />
                  Phone
                </th>
                {/* <th>City</th> */}
                <th>
                  <Icon.Star
                    className="phone-icon"
                    size={20}
                    strokeWidth={2}
                    color={"#412224"}
                    style={{ marginRight: "5px" }}
                  />
                  Reviews
                </th>
                <th>
                  <Icon.Briefcase
                    className="phone-icon"
                    size={20}
                    strokeWidth={2}
                    color={"#412224"}
                    style={{ marginRight: "5px" }}
                  />
                  Clients
                </th>
                <th>
                  <Icon.Award
                    className="phone-icon"
                    size={20}
                    strokeWidth={2}
                    color={"#412224"}
                    style={{ marginRight: "5px" }}
                  />
                  CC Trusted
                </th>
              </tr>
            </thead>
            <tbody>
              {list.map((profileInfo, index) => {
                const lawyerName = `${profileInfo.first_name} ${profileInfo.last_name}`;

                if (
                  lawyerName.toLowerCase().indexOf(searchInput) !== -1 ||
                  (
                    lawyerName.charAt(0).toUpperCase() + lawyerName.slice(1)
                  ).indexOf(searchInput) !== -1 ||
                  lawyerName.toUpperCase().indexOf(searchInput) !== -1
                ) {
                  return (
                    <tr
                      key={index}
                      className="table-row"
                      onClick={() => {
                        props.history.push(
                          { pathname: "/employee-profile" },
                          { state: profileInfo }
                        );
                      }}
                    >
                      <td>
                        <img
                          src={profileInfo.pictureURL}
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            marginRight: "10px",
                          }}
                        ></img>{" "}
                        {profileInfo.first_name + " " + profileInfo.last_name}
                      </td>
                      <td>{profileInfo.phone_number}</td>
                      {/* <td>{profileInfo.city}</td> */}
                      <td>{profileInfo.reviews.length}</td>
                      <td>
                        {profileInfo.client_connections
                          ? profileInfo.client_connections.length
                          : 0}
                      </td>
                      <td>
                        {profileInfo.cc_trusted ? profileInfo.cc_trusted : ""}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      )}

      {showImportRev && !showEmployees && !showEditProfile && (
        <div
          style={{
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
            marginTop: "50px",
            width: "100%",
            paddingBottom: "50px",
          }}
        >
          <div style={{ width: "100%" }}>
            <div style={{ display: "flex", flexFlow: "column" }}>
              <h4 className="cc-txt-dark">Import Reviews for Law Firm</h4>
              <strong className="cc-txt-dark">Drag/Drop CSV file:</strong>
              <p className="cc-txt-dark">
                Side Note: Make sure the reviews you are importing are in order
                of date and have a later date the current reviews this profile
                has.
              </p>
            </div>

            <div className="doc-input-container">
              <FilePond
                ref={uploadRef}
                files={files}
                allowMultiple={true}
                onupdatefiles={(fileItems) => {
                  setFiles(fileItems.map((fileItem) => fileItem.file));
                }}
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
              />
            </div>
            <Button
              onClick={() => {
                onFileUpload(files);
              }}
              variant="danger"
              style={{ width: "180px", height: "50px" }}
            >
              Submit
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default withAlert()(Employees);
