import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
// import "./CreateReviews.scss";
import * as Icon from "react-feather";
import { withAlert } from "react-alert";
import http from "../../../utils/http";

import { Button, Form, Spinner } from "react-bootstrap";
import { FilePond } from "react-filepond";

import "react-datepicker/dist/react-datepicker.css";
import useForm from "../../../hooks/useForm";
import environment from "../../../utils/environment";

import "./FirmEditProfile.scss";
import TextAreaField from "../../reusable/text_area_field/TextAreaField";
import SelectList from "../../reusable/select_list/SelectList";
import TextField from "../../reusable/text_field/TextField";
import PriceModal from "./price_modal/PriceModal";

const FirmEditProfile = (props) => {
  let { profile, refreshProfile } = props;

  const [picUrl, setPictureUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [firmPricesList, setFirmPricesList] = useState({});
  const [firmPerks, setFirmPerks] = useState({
    acceptsLegalAid: false,
    freeConsultations: false,
    providesFinancing: false,
  });

  const [acceptsLegalAid, setAcceptsLegalAid] = useState(false);
  const [freeConsultations, setFreeConsultations] = useState(false);
  const [providesFinancing, setProvidesFinancing] = useState(false);

  // const [loading, setAccountStatus] = useState(false);
  const uploadRef = useRef(null);

  const { inputs, setInputs, handleInputChange, handleSubmit } = useForm();

  const onImageClick = useCallback(() => {
    uploadRef.current.click();
  }, [uploadRef]);

  // const firmPricesList = {
  //   "Criminal Law": [
  //     {
  //       name: "Trial",
  //       price_type: "Fixed",
  //       price_min: "300",
  //       price_max: "500",
  //       notes: "These are some notes",
  //     },
  //     {
  //       name: "No Trial",
  //       price_type: "Fixed",
  //       price_min: "100",
  //       price_max: "0",
  //       notes: "These are some notes",
  //     },
  //   ],
  // };

  const addFirmPerk = async (typeOfPerk, typeOfPerkValue) => {
    try {
      const response = await http.post(
        `${environment.resolveApi().rest}/admin_firm/perk`,
        { lawFirmId: profile._id, typeOfPerk, typeOfPerkValue }
      );

      // setFirmPricesList(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getLawFirmPrices = async () => {
    try {
      console.log("here");
      const response = await http.get(
        `${environment.resolveApi().rest}/admin_firm/prices/${profile._id}`
      );

      setFirmPricesList(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getLawFirmPerks = async () => {
    try {
      console.log("here");
      const response = await http.get(
        `${environment.resolveApi().rest}/admin_firm/perks/${profile._id}`
      );

      console.log("response.data", response.data);

      if (response.data.accepts_legal_aid) {
        setAcceptsLegalAid(true);
      }

      if (response.data.free_consultations) {
        setFreeConsultations(true);
      }

      if (response.data.provides_financing) {
        setProvidesFinancing(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLawFirmPerks();
    getLawFirmPrices();
  }, []);

  const updateAccountStatus = async (changedStatus) => {
    try {
      setLoading(true);

      console.log(profile._id, changedStatus);

      const response = await http.post(
        `${environment.resolveApi().rest}/admin/law_firm/update_account_status`,
        {
          id: profile._id,
          newStatus: changedStatus,
        }
      );

      refreshProfile();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onFileUpload = async (e) => {
    e.persist();

    const file = e.target.files[0];

    //  If the file size (calculated in byte form) is than 1MB
    if (file.size > 1.0e6) {
      return props.alert.error("IMAGE SIZE MUST BE LESS THAN 1MB");
    }
    if (file.type !== "image/png" && file.type !== "image/jpeg") {
      return props.alert.error("IMAGE TYPE MUST BE PNG OR JPG");
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);

      formData.append("id", profile._id);

      const response = await http.uploadImage(
        `${environment.resolveApi().rest}/admin/law_firm/upload`,
        formData
      );
      setLoading(false);
      setPictureUrl(response.data.pictureURL);

      console.log(response.data.pictureURL);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    setInputs(profile);
    setLoading(false);
  }, []);

  const title = (title) => {
    return (
      <div className="firmDetailView__content__titleContainer">
        <span className="firmDetailView__content__title">{title}</span>

        {/* divider */}
        <div className="titleDivider">
          <div className="titleDivider__circle"></div>
          <hr className="titleDivider__line"></hr>
        </div>
      </div>
    );
  };

  if (loading) return <Spinner />;

  return (
    <div className="FirmEditProfile">
      <div className="firmEditProfile__row1">
        <div className="firmEditProfile__picWrap" onClick={onImageClick}>
          <span className="firmEditProfile__label">Profile Pic</span>
          <img
            className="firmEditProfile__pic "
            src={picUrl ? picUrl : inputs.companyLogo}
            alt=""
          />
          <input type="file" onChange={onFileUpload} ref={uploadRef} />
        </div>
        {profile.account_status == "active" ? (
          <div className="firmEditProfile__deactivate">
            <span className="firmEditProfile__label">Account Status</span>
            <span className="firmEditProfile__accountStatus__label--active">
              Active
            </span>
            <button
              className="firmEditProfile__deactivate__btn"
              onClick={() => {
                updateAccountStatus("deactivate");
              }}
            >
              Deactivate Law Firm
            </button>
          </div>
        ) : (
          <div className="firmEditProfile__deactivate">
            <span className="firmEditProfile__label">Account Status</span>
            <span className="firmEditProfile__accountStatus__label--deactivated">
              Deactivated
            </span>
            <button
              className="firmEditProfile__activate__btn"
              onClick={() => {
                updateAccountStatus("active");
              }}
            >
              Activate Law Firm
            </button>
          </div>
        )}
      </div>

      {/* ROW 2 ---- */}
      <div className="firmEditProfile__row2">
        <span className="firmEditProfile__label">Firm Perks</span>

        <div className="form-check mb-2">
          <input
            type="checkbox"
            className="form-check-input--firmEditProfile"
            name="current"
            value={acceptsLegalAid}
            checked={acceptsLegalAid}
            onChange={() => {
              addFirmPerk("acceptsLegalAid", !acceptsLegalAid);
              setAcceptsLegalAid(!acceptsLegalAid);
            }}
            id="current"
          />
          <label htmlFor="current" className="form-check-label">
            Accepts legal aid
          </label>
        </div>

        <div className="form-check mb-2">
          <input
            type="checkbox"
            className="form-check-input--firmEditProfile"
            name="current"
            value={freeConsultations}
            checked={freeConsultations}
            onChange={() => {
              addFirmPerk("freeConsultations", !freeConsultations);
              setFreeConsultations(!freeConsultations);
            }}
            id="current"
          />
          <label htmlFor="current" className="form-check-label">
            Free initial consultations
          </label>
        </div>

        <div className="form-check mb-2">
          <input
            type="checkbox"
            className="form-check-input--firmEditProfile"
            name="current"
            value={providesFinancing}
            checked={providesFinancing}
            onChange={() => {
              addFirmPerk("providesFinancing", !providesFinancing);
              setProvidesFinancing(!providesFinancing);
            }}
            id="current"
          />
          <label htmlFor="current" className="form-check-label">
            Financing options available
          </label>
        </div>
      </div>

      {/* ROW 3 ---- */}
      <div className="firmEditProfile__row3">
        <div>
          <span className="firmEditProfile__label">Pricing</span>

          <PriceModal
            lawFirmId={profile._id}
            getLawFirmPrices={getLawFirmPrices}
          />

          <div className="firmDetailView__content">
            {firmPricesList ? (
              Object.keys(firmPricesList).map((lawType) => (
                <div>
                  <h3 style={{ marginTop: 50 }}>{lawType} fees</h3>
                  <table className="priceTable">
                    <thead id="">
                      <tr>
                        <th className="firmDetailView__content__priceTable__th">
                          Service
                        </th>
                        <th className="firmDetailView__content__priceTable__th">
                          Price
                        </th>
                        <th className="firmDetailView__content__priceTable__th">
                          Notes
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {firmPricesList[lawType].map((price, index) => (
                        <tr key={index}>
                          <td>
                            <div className="">{price.name}</div>
                          </td>
                          <td>
                            <div className="">
                              ${price.price_min}{" "}
                              {price.price_max > 0 && `- ${price.price_max}`}{" "}
                              {price.price_type == "Hourly" ? "hourly" : ""}
                            </div>
                          </td>

                          <td>
                            <div className="">{price.notes}</div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))
            ) : (
              <span>This Firm has no prices listed</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAlert()(FirmEditProfile);
