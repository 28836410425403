import React, { useState, useEffect } from 'react';
import { NavLink, withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../../../actions/authActions';
import * as Icon from 'react-feather';

import http from '../../../utils/http';
import environment from '../../../utils/environment';


import './Navbar.scss';

const isSignupRoutes = (match, location) => [
  '/create-signup-link',
].includes(location.pathname);

const isFirmRoutes = (match, location) => [
  '/law-firms',
  '/employees',
  '/employee-profile',
].includes(location.pathname);

const isSoleRoutes = (match, location) => [
  '/sole-practitioners',
  '/practitioner',
].includes(location.pathname);

const Navbar = (props) => {
  const logout = () => {
    props.logoutUser();
  };

  const [homeLink] = useState(false);
  
  const [adminProfile, setAdminProfile] = useState(false);


  const getUserProfile = async () => {
    try {
      const response = await http.get(`${environment.resolveApi().rest}/admin/profile`);
      setAdminProfile(response.data);
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    getUserProfile();
  }, []);



  return (
    
        <div className="admin-outer-container">
         
           
            <Link exact to="/" className="navbar-brand">
              <img className="logo" style={{width: '200px'}} src={require('../../layout/navbar/images/top-navbar-logo.svg')} alt="" />
            </Link>

          
          
              
              
              <div className="admin-navbar-links-wrapper">
                <div className="nav-item">
                  <NavLink exact to="/create-signup-link" className="nav-link" isActive={isSignupRoutes}  activeClassName="selected">
                    <h3>Signup Links</h3>
                  </NavLink>
                </div>
                <div className="nav-item">
                <NavLink exact to="/law-firms" className="nav-link" isActive={isFirmRoutes}  activeClassName="selected">
                  <h3>Law Firms</h3>
                </NavLink>
                </div>
                
                <div className="nav-item">
                <NavLink exact to="/sole-practitioners" className="nav-link" isActive={isSoleRoutes}  activeClassName="selected">
                    <h3>Sole Practitioners</h3>
                </NavLink>
                </div>
              </div>

              
              <div className="navbar-logout-container">
                  <p>{adminProfile.name}</p>
                  <Icon.LogOut onClick={logout} className="logout-icon" size={30} strokeWidth={2} color={"#412224"} style={{marginRight: '5px'}}  />
              </div>

           
        
        </div>
      
   
  );
};


Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
};



const mapStateToProps = state => ({
  auth: state.auth,
});


export default withRouter(connect(mapStateToProps, { logoutUser })(Navbar));
