import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
// import './CreateReviews.scss';
import * as Icon from "react-feather";
import { withAlert } from "react-alert";
import { Button, Form } from "react-bootstrap";
import { FilePond } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import "./Practitioner.scss";
import useForm from "../hooks/useForm";

import http from "../utils/http";
import environment from "../utils/environment";
import Spinner from "./Spinner.jsx";

import SelectList from "./reusable/select_list/SelectList";

import "react-datepicker/dist/react-datepicker.css";
import AddReview from "./AddReview.jsx";
import EmployeeEditProfile from "./admin_edit_profile/employee_edit_profile/EmployeeEditProfile";

const ccTrustedOptions = [
  { label: "* Select", value: 0 },
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

const EmployeeProfile = (props) => {
  const onSubmit = async (inputs) => {
    try {
      if (!inputs.ccTrusted) {
        props.alert.error("Must fill in all fields!");
        return;
      }
      const response = await http.post(
        `${environment.resolveApi().rest}/admin/employee/profile/update`,
        { employeeId: employee._id, ccTrusted: inputs.ccTrusted }
      );
      props.alert.success("Updated Profile!");
      setEditProfile(false);
      getEmployeeProfile();
    } catch (error) {
      console.log(error);
    }
  };

  if (!props.location.state) {
    props.history.push("/");
  } else {
    var profileInfo = props.location.state.state;
  }

  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showImportRev, setImportRev] = useState(false);
  const [showEditProfile, setEditProfile] = useState(false);
  const [showAddRev, setAddRev] = useState(false);
  const uploadRef = useRef(null);

  const { inputs, setInputs, handleInputChange, handleSubmit } =
    useForm(onSubmit);

  const onFileUpload = async (fileList) => {
    try {
      props.alert.info("Loading...");

      let file = fileList[0];
      const formData = new FormData();
      formData.append("file", file);

      const response = await http.post(
        `${environment.resolveApi().rest}/admin/upload/file`,
        formData
      );
      await http.post(
        `${environment.resolveApi().rest}/admin/create/review/employee`,
        { profileId: employee._id, csvFileUrl: response.data }
      );
      props.alert.success("Reviews Updated Successfully!");
      setImportRev(false);

      getEmployeeProfile();
    } catch (error) {
      if (error.file) {
        props.alert.error(error.file);
      }
      if (error.type) {
        props.alert.error(error.type);
      }
      if (error.size) {
        props.alert.error(error.size);
      }
      console.error(error);
    }
  };

  const [employee, setProfile] = useState([]);

  const getEmployeeProfile = async () => {
    try {
      const response = await http.post(
        `${environment.resolveApi().rest}/admin/profile/employee`,
        { profileId: profileInfo._id }
      );
      setProfile(response.data);
      setInputs(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    getEmployeeProfile();
    setLoading(false);
  }, []);

  if (loading) return <Spinner />;

  return (
    <div className="Practitioner">
      <div className="cc-flex-row" style={{ alignItems: "center" }}>
        {/* <Icon.ArrowLeftCircle onClick={()=> {props.history.push('/law-firms')} } className="back-btn" size={50} strokeWidth={2} color={"#D01E1B"} style={{marginRight: '20px'}}  /> */}

        <img
          src={employee.pictureURL}
          style={{
            width: "100px",
            height: "100px",
            borderRadius: "50%",
            marginTop: "10px",
            marginRight: "20px",
          }}
        ></img>
        <div>
          <h3 className="cc-txt-dark">
            {employee.first_name + " " + employee.last_name}
          </h3>

          <div className="cc-flex-row">
            <strong className="cc-txt-dark">
              {employee.law_title} -{" "}
              {employee.client_connections
                ? `Clients: ${employee.client_connections.length}`
                : ""}{" "}
              - Reviews: {employee.reviews ? employee.reviews.length : ""}
            </strong>
          </div>
        </div>
        <Button
          variant="danger"
          href={`https://app.counselconnect.ca/profile/employee/${employee.handle}`}
          target="_blank"
          style={{ marginLeft: "50px" }}
        >
          <Icon.User
            className="phone-icon"
            size={20}
            strokeWidth={2}
            color={"#FFFFFF"}
            style={{ marginRight: "5px" }}
          />
          Social Profile
        </Button>
        {/* <Button variant="dark" style={{width: '180px', marginLeft: '30px'}} onClick={()=> {setImportRev(false);}}>Edit Profile</Button> */}
        <Button
          variant="dark"
          style={{ marginLeft: "30px" }}
          onClick={() => {
            setEditProfile(true);
            setImportRev(false);
            setAddRev(false);
          }}
        >
          <Icon.Edit
            className="phone-icon"
            size={20}
            strokeWidth={2}
            color={"#FFFFFF"}
            style={{ marginRight: "5px" }}
          />
          Edit Profile
        </Button>

        <Button
          variant="dark"
          style={{ marginLeft: "30px" }}
          onClick={() => {
            setAddRev(true);
            setImportRev(false);
            setEditProfile(false);
          }}
        >
          <Icon.PlusCircle
            className="phone-icon"
            size={20}
            strokeWidth={2}
            color={"#FFFFFF"}
            style={{ marginRight: "5px" }}
          />
          Add Review
        </Button>

        <Button
          variant="dark"
          style={{ marginLeft: "30px" }}
          onClick={() => {
            setImportRev(true);
            setEditProfile(false);
            setAddRev(false);
          }}
        >
          <Icon.Star
            className="phone-icon"
            size={20}
            strokeWidth={2}
            color={"#FFFFFF"}
            style={{ marginRight: "5px" }}
          />
          Import Reviews
        </Button>
      </div>

      <hr style={{ width: "100%", marginTop: "30px" }}></hr>

      {showAddRev && !showEditProfile && !showImportRev && (
        <AddReview
          profileId={employee._id}
          userType="Employee"
          getProfile={getEmployeeProfile}
          showPage={setAddRev}
          {...props}
        />
      )}

      {showEditProfile && !showImportRev && !showAddRev && (
        <div>
          <h3 className="cc-txt-dark">Edit Profile</h3>

          <EmployeeEditProfile
            profile={employee}
            userType="Employee"
            refreshProfile={() => {
              getEmployeeProfile();
            }}
          />

          {/*       

      <Form onSubmit={handleSubmit}>
        
        <SelectList
            title="CC Trusted"
            placeholder=""
            name="ccTrusted"
            value={inputs.cc_trusted}
            onChange={handleInputChange}
            options={ccTrustedOptions}
            
            info=""
            className="law-portal-select-list-input"
            lawPortal
          />

        <Button type="submit" variant="danger" style={{width: '180px', height: '50px'}} >Submit</Button>

      </Form> */}
        </div>
      )}

      {showImportRev && !showEditProfile && !showAddRev && (
        <div
          style={{
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
            marginTop: "50px",
          }}
        >
          <div style={{ width: "100%" }}>
            <div style={{ display: "flex", flexFlow: "column" }}>
              <h4 className="cc-txt-dark">Import Reviews</h4>
              <strong className="cc-txt-dark">Drag/Drop CSV file:</strong>
              <p className="cc-txt-dark">
                Side Note: Make sure the reviews you are importing are in order
                of date and have a later date the current reviews this profile
                has.
              </p>
            </div>

            <div className="doc-input-container">
              <FilePond
                ref={uploadRef}
                files={files}
                allowMultiple={true}
                onupdatefiles={(fileItems) => {
                  setFiles(fileItems.map((fileItem) => fileItem.file));
                }}
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
              />
            </div>
            <Button
              onClick={() => {
                onFileUpload(files);
              }}
              variant="danger"
              style={{ width: "180px", height: "50px" }}
            >
              Submit
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default withAlert()(EmployeeProfile);
