import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import './CreateSignupLink.scss';
import * as Icon from 'react-feather';
import { withAlert } from "react-alert";
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import useForm from '../hooks/useForm';
import http from '../utils/http';
import environment from '../utils/environment';
import {CopyToClipboard} from 'react-copy-to-clipboard';
// import microtip from 'microtip/microtip.css'

const CreateSignupLink = (props) => {

    const onSubmit = async (inputs) => {
        try {
            // console.log(inputs.email)
            // console.log(inputs.type)
            const response = await http.post(`${environment.resolveApi().rest}/admin/create/signuplink`, {email: inputs.email, type: inputs.type});
            // console.log(response.data);
            handleClose();
            props.alert.success("Signup Link Created!");
            resetComponent();
        } catch (error) {
            if(error.type) {
                props.alert.error(error.type);
            }
            if(error.email) {
                props.alert.error(error.email);
            }
            console.log(error);
        }
    }
    
    const resetComponent = () => {
        getListOfSignupLinks();
    }

    const [show, setShow] = useState(false);
    const [list, setList] = useState([]);
    const [copyLink, setCopyLink] = useState('Copy Signup Link');
    const { inputs, handleInputChange, handleSubmit } = useForm(onSubmit);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  

    const getListOfSignupLinks = async () => {
        try {
            const response = await http.get(`${environment.resolveApi().rest}/admin/list/signuplink`);
            // console.log(response.data);
            setList(response.data);
        } catch (error) {
            console.log(error);
        }
    }
    
    
    useEffect(() => {
      getListOfSignupLinks();
    }, []);

   
    

  return (
    <div className="CreateSignupLink">
    
        <div className="cc-title-btn-wrap">
            <h2 className="cc-txt-dark">Signup Links</h2>
            <button className="cc-red-btn" onClick={handleShow}> <Icon.Plus className="phone-icon" size={20} strokeWidth={2} color={"#FC6970"} style={{marginRight: '10px'}}  /> Add</button>
        </div>


        <table className="table" style={{ backgroundColor: 'white' }}>
            <thead id="table-header">
              <tr>
                <th><Icon.User className="phone-icon" size={20} strokeWidth={2} color={"#412224"} style={{marginRight: '5px'}}  />Given To</th>
                <th><Icon.Link className="phone-icon" size={20} strokeWidth={2} color={"#412224"} style={{marginRight: '5px'}}  />Link</th>
                <th><Icon.Calendar className="phone-icon" size={20} strokeWidth={2} color={"#412224"} style={{marginRight: '5px'}}  />Date Given</th>
                <th><Icon.Calendar className="phone-icon" size={20} strokeWidth={2} color={"#412224"} style={{marginRight: '5px'}}  />Date Updated</th>
                <th><Icon.Clipboard className="phone-icon" size={20} strokeWidth={2} color={"#412224"} style={{marginRight: '5px'}}  />Status</th>
                
              </tr>
            </thead>
            <tbody>
             
            {list.map((link, index) => (
              <tr key={index}>
                    <td>{link.email}</td>
                    {link.short_id ? 
                    <CopyToClipboard text={ link.type == "Sole" ? `https://app.counselconnect.ca/signup/${link.short_id}` : `https://firm.counselconnect.ca/signup/${link.short_id}`}>
                    <td className="copy-link" target="_blank" aria-label={copyLink} onClick={() => { props.alert.success(`Link Copied for ${link.email}!`);; }}>Copy Link</td>
                    </CopyToClipboard>
                    :
                    <td>Used</td>
                    }
                    <td>{link.created_at.split("T")[0]}</td>
                    <td>{link.updated_at ? link.updated_at.split("T")[0] : ''}</td>
                    <td style={link.active ? {backgroundColor: '#E3FFCA', color:'#00D209'} : {backgroundColor: '#F9E7E9', color:'#D2000D'}}>{link.active ? 'Active' : 'Deactivated'}</td>
              </tr>
            ))}


                
            </tbody>
          </table>


  

  

      <Modal show={show} onHide={handleClose}>
      <Form  onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title style={{fontFamily: 'DM Sans'}}>Create Signup Link</Modal.Title>
          
        </Modal.Header>
        <Modal.Body>
            

      
 
  <Form.Group as={Row} controlId="formPlaintextEmail" >
    <Form.Label column sm="2">
    Email
    </Form.Label>
    <Col sm="10">
      <Form.Control 
        name="email"
        value={inputs.email}
        onChange={handleInputChange}
        type="email" 
        placeholder="Email" 
        />
    </Col>
  </Form.Group>

    
  <Form.Group as={Row} controlId="exampleForm.ControlSelect1">
    <Form.Label column sm="2">Type</Form.Label>
  
    <Col sm="10">
    
    <Form.Control as="select"
        name="type"
        value={!inputs.type ? 0 : inputs.type}
        onChange={handleInputChange}
    >
        <option>Select Type</option>
        <option>Firm</option>
        <option>Sole</option>
    </Form.Control>
    </Col>
  </Form.Group>



            
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button type="submit" variant="primary" >
            Save Changes
          </Button>
          
        </Modal.Footer>
        </Form>
      </Modal>
 



    </div>
  );
};


export default withAlert()(CreateSignupLink);


