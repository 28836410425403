import React, { Component } from 'react';
import MicroModal from 'micromodal';
// import DatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";
// import "./datepicker.css";
import './modal.css';

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
    };
  }

  componentDidMount() {
    this.setState({
      title: this.props.title,
      modalId: this.props.modalId,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.title !== this.props.title) {
      this.setState({
        title: this.props.title,
      });
    }
  }


  render() {
    return (
      <div>
        <div className="modal micromodal-slide" id={this.state.modalId} aria-hidden="true">
          <div className="modal__overlay" id="modal-overlay" tabIndex="-1" data-micromodal-close>
            <div id="modal-container" role="dialog" aria-modal="true" aria-labelledby="modal-1-title">
              <header className="modal-title-container">
                <h1 className="modal-title">
                  {this.state.title}
                </h1>
              </header>
              <main className="modal__content" id="modal-1-content">
                {this.props.children}
              </main>


            </div>

          </div>
        </div>

        {MicroModal.init()}
      </div>
    );
  }
}

export default Modal;
