import React, { useState, useEffect, useCallback } from "react";
import SelectList from "../../../reusable/select_list/SelectList";
import TextField from "../../../reusable/text_field/TextField";
import TextAreaField from "../../../reusable/text_area_field/TextAreaField";
import { Button, Form } from "react-bootstrap";
import useForm from "../../../../hooks/useForm";
import { withAlert } from "react-alert";
import environment from "../../../../utils/environment";
import http from "../../../../utils/http";
import axios from "axios";

const lawCategories = [
  { label: "Select category", value: null },
  { label: "Criminal Law", value: "Criminal Law" },
  { label: "Commercial Law", value: "Commercial Law" },
  { label: "Civil Law", value: "Civil Law" },
  { label: "Employment Law", value: "Employment Law" },
  { label: "Family Law", value: "Family Law" },
  { label: "Immigration Law", value: "Immigration Law" },
  { label: "Real Estate", value: "Real Estate" },
];

const priceTypes = [
  { label: "Select price type", value: null },
  { label: "Fixed", value: "Fixed" },
  { label: "Hourly", value: "Hourly" },
];

const PriceModal = (props) => {
  let { lawFirmId, getLawFirmPrices } = props;
  const [price, setPrice] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [priceRangeChecked, setPriceRangeChecked] = useState(false);

  const toggleModal = () => {
    setInputs("");
    setShowModal((prevShowModal) => !prevShowModal);
  };

  const onSubmit = async (inputs) => {
    try {
      const payload = {
        lawFirmId,
        nameOfService: inputs.nameOfService,
        lawCategory: inputs.lawCategory,
        priceType: inputs.priceType,
        priceMin: inputs.priceMin,
        priceMax: inputs.priceMax ? inputs.priceMax : "0",
        notes: inputs.notes,
      };

      console.log("payload", payload);

      if (
        !payload.nameOfService ||
        !payload.lawCategory ||
        !payload.priceType ||
        !payload.priceMin ||
        (priceRangeChecked && !payload.priceMax)
      ) {
        props.alert.error("Missing Input!");
        return console.error("missing message");
      }

      try {
        const response = await http.post(
          `${environment.resolveApi().rest}/admin_firm/new_price/`,
          payload
        );
        console.log("Response:", response.data);

        await getLawFirmPrices();

        toggleModal();
      } catch (error) {
        console.log(error);
      }

      //   history.push("/signup/request/success");
    } catch (error) {
      console.log(error);
    }
  };

  const { inputs, setInputs, handleInputChange, handleSubmit } =
    useForm(onSubmit);

  return (
    <div>
      <Button onClick={toggleModal} type="button" variant="dark">
        Add new price
      </Button>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Create a new price</h2>

            <form onSubmit={handleSubmit} className="add-review-form-container">
              <div className="mb-2">
                <TextField
                  className="lw-portal-text-input"
                  title="Name of Service"
                  placeholder=""
                  type="text"
                  name="nameOfService"
                  value={inputs.nameOfService}
                  onChange={handleInputChange}
                  lawPortal
                />
              </div>

              <div className="cc-flex-row mt-2">
                <div className="mr-4">
                  <SelectList
                    title="Law Category"
                    placeholder=""
                    name="lawCategory"
                    value={inputs.lawCategory}
                    onChange={handleInputChange}
                    options={lawCategories}
                    info=""
                    className="law-portal-select-list-input"
                    lawPortal
                  />
                </div>

                <SelectList
                  title="Price Type"
                  placeholder=""
                  name="priceType"
                  value={inputs.priceType}
                  onChange={handleInputChange}
                  options={priceTypes}
                  info=""
                  className="law-portal-select-list-input"
                  lawPortal
                />
              </div>

              <div className="form-check mb-2">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="current"
                  value={priceRangeChecked}
                  checked={priceRangeChecked}
                  onChange={() => {
                    setPriceRangeChecked(!priceRangeChecked);
                  }}
                  id="current"
                />
                <label htmlFor="current" className="form-check-label">
                  Price Ranges
                </label>
              </div>

              {!priceRangeChecked ? (
                <div className="mb-2">
                  <TextField
                    className="lw-portal-text-input"
                    title="Price"
                    placeholder=""
                    type="text"
                    name="priceMin"
                    value={inputs.priceMin}
                    onChange={handleInputChange}
                    lawPortal
                  />
                </div>
              ) : (
                <div className="mb-2">
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      className="lw-portal-text-input"
                      title="Price Min"
                      placeholder=""
                      type="text"
                      name="priceMin"
                      value={inputs.priceMin}
                      onChange={handleInputChange}
                      lawPortal
                    />{" "}
                    <TextField
                      className="lw-portal-text-input"
                      title="Price Max"
                      placeholder=""
                      type="text"
                      name="priceMax"
                      value={inputs.priceMax}
                      onChange={handleInputChange}
                      lawPortal
                    />{" "}
                  </div>
                </div>
              )}

              <TextAreaField
                className="modal-text-area-field text-field-msg"
                title="Notes"
                placeholder=""
                name="notes"
                type="text"
                value={inputs.notes}
                onChange={handleInputChange}
                autoComplete="off"
                style={{ maxWidth: "100%" }}
              />

              <div
                style={{
                  display: "flex",
                  flexFlow: "row",
                  alignItems: "center",
                }}
              >
                <Button
                  type="button"
                  variant="dark"
                  onClick={toggleModal}
                  style={{
                    width: "100px",
                    height: "50px",
                    marginTop: "40px",
                    marginRight: 10,
                  }}
                >
                  {" "}
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="danger"
                  style={{ width: "180px", height: "50px", marginTop: "40px" }}
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}

      <style jsx>{`
        .modal {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .modal-content {
          background-color: white;
          padding: 20px;
          border-radius: 4px;
          text-align: left;
          width: 500px;
        }

        button {
          margin-top: 10px;
        }
      `}</style>
    </div>
  );
};

export default withAlert()(PriceModal);
