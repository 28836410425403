import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getCurrentProfile } from './actions/profileActions';

import Navbar from './components/layout/navbar/Navbar';
import Dashboard from './components/dashboard/Dashboard';
import SolePractitioners from './components/SolePractitioners.jsx'
import LawFirmsList from './components/LawFirmsList';
import Practitioner from './components/Practitioner.jsx';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Employees from './components/Employees';
import EmployeeProfile from './components/EmployeeProfile';

import PrivateRoute from './components/reusable/PrivateRoute';
import NotFound from './components/reusable/not_found/NotFound';
// import AddEducation from "./components/profile/add_creds/AddEducation";
// import Profiles from "./components/profiles/Profiles";
import CreateSignupLink from './components/CreateSignupLink.jsx';
import './style/index.scss';
import NewEmployeeModal from './components/profile/edit_employees/NewEmployeeModal';

const Main = (props) => {
  // runs once on mount
  useEffect(() => {
    props.getCurrentProfile();
  }, []);

  return (
    <div className="Main">
      <Navbar />
      <Switch>
        <PrivateRoute exact path="/" component={Dashboard} />
        <PrivateRoute exact path="/create-signup-link" component={CreateSignupLink} />
        <PrivateRoute exact path="/sole-practitioners" component={SolePractitioners} />
        <PrivateRoute exact path="/law-firms" component={LawFirmsList} />
        <PrivateRoute exact path="/practitioner" component={Practitioner} />
        <PrivateRoute exact path="/employees" component={Employees} />
        <PrivateRoute exact path="/employee-profile" component={EmployeeProfile} />
        
        
        {/* <PrivateRoute exact path="/create-profile" component={CreateProfile} />
        <PrivateRoute exact path="/edit-profile" component={EditProfile} />
        <PrivateRoute exact path="/edit-employees" component={Employees} /> */}
        {/* <PrivateRoute exact path="/new-employee" component={NewEmployeeModal} /> */}
        {/* <PrivateRoute exact path="/add-experience" component={Credentials} /> */}
        {/* <PrivateRoute exact path="/edit-hours" component={AddAvailability} />
        <PrivateRoute exact path="/account" component={AccountInfo} />
        <PrivateRoute exact path="/contact" component={Contact} /> */}
        <PrivateRoute exact path="*" component={NotFound} />
      </Switch>
    </div>
  );
};

Main.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
};

export default connect(null, { getCurrentProfile })(Main);
