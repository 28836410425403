import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import './CreateReviews.scss';
import * as Icon from 'react-feather';
import { withAlert } from "react-alert";
import http from '../utils/http';
import environment from '../utils/environment';
import Spinner from './Spinner.jsx';


const SolePractitioners = (props) => {

   
  // 
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
    
  const onSearch = (event) => {
      setSearchInput(event.target.value);
  }

  const getSolePracList = async () => {
      try {
          const response = await http.get(`${environment.resolveApi().rest}/admin/list/solepractitioners`);
          console.log(response.data);
          setList(response.data);
      } catch (error) {
          console.log(error);
      }
  }


  useEffect(() => {
    setLoading(true);
    getSolePracList();
    setLoading(false);
  }, []);



  if (loading) return <Spinner />;

  return (
    <div className="CreateSignupLink SolePractitioners">
   
    <div className="users-list-search-add-wrap">
        <h2 className="cc-txt-dark">Sole Practitioners</h2>
        <input className="users-search-field" type="search" placeholder="Search Name..." autoComplete="off" onChange={onSearch}></input>
    </div>
     
        <table className="table" style={{ backgroundColor: 'white' }}>
            <thead id="table-header">
             
             
              <tr>
                <th><Icon.User className="phone-icon" size={20} strokeWidth={2} color={"#412224"} style={{marginRight: '5px'}}  />Name</th>
                <th><Icon.BookOpen className="phone-icon" size={20} strokeWidth={2} color={"#412224"} style={{marginRight: '5px'}}  />Title</th>
                <th><Icon.Phone className="phone-icon" size={20} strokeWidth={2} color={"#412224"} style={{marginRight: '5px'}}  />  Phone</th>
                <th><Icon.MapPin className="phone-icon" size={20} strokeWidth={2} color={"#412224"} style={{marginRight: '5px'}}  />  City</th>
                <th><Icon.Briefcase className="phone-icon" size={20} strokeWidth={2} color={"#412224"} style={{marginRight: '5px'}}  /> Clients</th>
                <th><Icon.Star className="phone-icon" size={20} strokeWidth={2} color={"#412224"} style={{marginRight: '5px'}}  />  Reviews</th>
                <th><Icon.Award className="phone-icon" size={20} strokeWidth={2} color={"#412224"} style={{marginRight: '5px'}}  />CC Trusted</th>

                <th><Icon.Key className="phone-icon" size={20} strokeWidth={2} color={"#412224"} style={{marginRight: '5px'}}  /> Joined</th>
              
                
              </tr>
            </thead>
            <tbody>


            {list.map((profileInfo, index) => {

            const lawyerName = `${profileInfo.first_name} ${profileInfo.last_name}`;

            if (lawyerName.toLowerCase().indexOf(searchInput) !== -1 || (lawyerName.charAt(0).toUpperCase() + lawyerName.slice(1)).indexOf(searchInput) !== -1 || (lawyerName.toUpperCase()).indexOf(searchInput) !== -1 ) 
            {
              return (
                <tr key={index} className="table-row" onClick={()=>{props.history.push({pathname: '/practitioner'}, {state: profileInfo} )}}>
                  <td><img src={profileInfo.pictureURL} style={{width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px'}}></img> {profileInfo.first_name + ' ' + profileInfo.last_name}</td>
                  <td>{profileInfo.status}</td>
                  <td>{profileInfo.phonenumber}</td>
                  <td>{profileInfo.city}</td>
                  <td>{profileInfo.client_connections ? profileInfo.client_connections.length : 0}</td>
                  <td>{profileInfo.reviews.length}</td>
                  <td>{profileInfo.cc_trusted ? profileInfo.cc_trusted : ''}</td>
                  <td>{profileInfo.createdAt ? profileInfo.createdAt.split("T")[0] : ''}</td>
                </tr>
              );
            }
          }
        )}


                
            </tbody>
          </table>
    


    </div>
  );
};


export default withAlert()(SolePractitioners);


