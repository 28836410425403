import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import environment from './utils/environment';
import { checkLogin, logoutUser } from './actions/authActions';
import store from './utils/store';
import http from './utils/http';
import Spinner from './components/reusable/spinner/Spinner';
import SignUp from './components/auth/SignUp';
import Login from './components/auth/Login';

import Main from './Main';
import PrivateRoute from './components/reusable/PrivateRoute';

import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import PasswordResetRequest from './components/auth/PasswordResetRequest.jsx';
import ResetPassword from './components/auth/ResetPassword.jsx';


const NETWORK_ERROR_CODE = 500;

  // optional cofiguration
  const options = {
    position: positions.TOP_RIGHT,
    timeout: 3000,
    offset: "50px",
    transition: transitions.FADE,
    containerStyle: {
      zIndex: 9999
    }
  };
  

const App = () => {
  const [isChecking, setChecking] = useState(true);
  const [isConnected, setConnected] = useState(false);


  const checkAdminUser = async() => {
    try 
    {
      await http.get(`${environment.resolveApi().rest}/admin/isAdmin`);
    } catch (error) {
      await http.post(`${environment.resolveApi().rest}/user/logout`);
      window.history.push('/login');
      console.log(error);
   }
}


  // check if the server is up
  useEffect(async () => {
    try {
      // await http.get(`https://api.counselconnect.ca/`);
      setConnected(true);
      await store.dispatch(checkLogin());
     
    } catch (err) {
      console.log(err)
 
      // if GET / throws a network error, then server is unreachable
      // if (err.response.status !== NETWORK_ERROR_CODE) {
      //   setConnected(true);
      //   await store.dispatch(checkLogin());
      // }
    }

    // Run an setInterval every 15 seconds  
    setInterval(() => {
      (async function () {
        const clientVersion = environment.resolveVersion().version;
        
        // Call API
        const response = await http.post(`${environment.resolveApi().rest}/admin/version/${clientVersion}` );
        console.log(response.data)
        if (response.data == true) {
          console.log("NEW VERSION --- RELOADING CACHE NOW!");
          // Reload the current resources from the server
          alert('Version update, pls click "OK".');
          window.location.reload(true);
        }
        
      })()
      // 1 minute - 60000
      }, 60000);

    setChecking(false);
  }, []);



  

  if (isChecking) return <Spinner />;
  // if (!isConnected) {
  //   return (
  //     <div>
  //       <h1>Can't connect to the server :(</h1>
  //       <p>(Make sure you have it running)</p>
  //     </div>
  //   );
  // }

  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <Provider store={store}>
        <Router>
          <Switch>
            <Route exact path="/login" component={Login} />
            {/* <Route exact path="/signup/request" component={SignUpRequest} /> */}
            {/* <Route exact path="/signup/request/success" component={SignUpRequestSuccess} /> */}
            <Route exact path="/signup/:shortId" component={SignUp} />
            <Route exact path="/reset-password" component={PasswordResetRequest} />
            <Route exact path="/reset-password/:shortId" component={ResetPassword} />

        
            <PrivateRoute path="/" component={Main} />
          </Switch>
        </Router>
      </Provider>
  </AlertProvider>
  );
};





export default App;
