import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
// import "./CreateReviews.scss";
import * as Icon from "react-feather";
import { withAlert } from "react-alert";
import http from "../../../utils/http";

import { Button, Form, Spinner } from "react-bootstrap";
import { FilePond } from "react-filepond";

import "react-datepicker/dist/react-datepicker.css";
import useForm from "../../../hooks/useForm";
import environment from "../../../utils/environment";

const EmployeeEditProfile = (props) => {
  let { profile, refreshProfile } = props;

  const [picUrl, setPictureUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const uploadRef = useRef(null);

  const { inputs, setInputs, handleInputChange, handleSubmit } = useForm();

  const updateAccountStatus = async (changedStatus) => {
    try {
      setLoading(true);

      console.log(profile._id, changedStatus);

      const response = await http.post(
        `${environment.resolveApi().rest}/admin/employee/update_account_status`,
        {
          id: profile._id,
          newStatus: changedStatus,
        }
      );

      refreshProfile();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onImageClick = useCallback(() => {
    uploadRef.current.click();
  }, [uploadRef]);

  const onFileUpload = async (e) => {
    e.persist();

    const file = e.target.files[0];

    //  If the file size (calculated in byte form) is than 1MB
    if (file.size > 1.0e6) {
      return props.alert.error("IMAGE SIZE MUST BE LESS THAN 1MB");
    }
    if (file.type !== "image/png" && file.type !== "image/jpeg") {
      return props.alert.error("IMAGE TYPE MUST BE PNG OR JPG");
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);

      formData.append("id", profile._id);

      const response = await http.uploadImage(
        `${environment.resolveApi().rest}/admin/employee/upload`,
        formData
      );
      setLoading(false);
      setPictureUrl(response.data.pictureURL);

      console.log(response.data.pictureURL);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    console.log("profile", profile);
    setInputs(profile);
    setLoading(false);
  }, []);

  if (loading) return <Spinner />;

  return (
    <div className="FirmEditProfile">
      <div className="firmEditProfile__picWrap" onClick={onImageClick}>
        <span className="firmEditProfile__label">Profile Pic</span>
        <img
          className="firmEditProfile__pic "
          src={picUrl ? picUrl : inputs.pictureURL}
          alt=""
        />
        <input type="file" onChange={onFileUpload} ref={uploadRef} />
      </div>
      {profile.account_status == "active" ? (
        <div className="firmEditProfile__deactivate">
          <span className="firmEditProfile__label">Account Status</span>
          <span className="firmEditProfile__accountStatus__label--active">
            Active
          </span>
          <button
            className="firmEditProfile__deactivate__btn"
            onClick={() => {
              updateAccountStatus("deactivate");
            }}
          >
            Deactivate Employee
          </button>
        </div>
      ) : (
        <div className="firmEditProfile__deactivate">
          <span className="firmEditProfile__label">Account Status</span>
          <span className="firmEditProfile__accountStatus__label--deactivated">
            Deactivated
          </span>
          <button
            className="firmEditProfile__activate__btn"
            onClick={() => {
              updateAccountStatus("active");
            }}
          >
            Activate Employee
          </button>
        </div>
      )}
    </div>
  );
};

export default withAlert()(EmployeeEditProfile);
