import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import './Dashboard.scss';
import * as Icon from 'react-feather';
import Spinner from '../Spinner.jsx';

import http from '../../utils/http';
import environment from '../../utils/environment';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {  withRouter } from 'react-router-dom';
import { checkLogin, logoutUser } from '../../actions/authActions';


const Dashboard = (props) => {


  const [loading, setLoading] = useState(false);
  const [analytics, setAnalytics] = useState(false);
  

  const getDashboardAnalytics = async () => {
    try {
        const response = await http.get(`${environment.resolveApi().rest}/admin/analytics/list/`);
        setAnalytics(response.data);
    } catch (error) {
        console.log(error);
    }
  }


  const checkAdminUser = async() => {
      try 
      {
        await http.get(`${environment.resolveApi().rest}/admin/isAdmin`);
      } catch (error) {

        window.location.reload(true);
        
        console.log(error);
     }
  }

useEffect(() => {
  checkAdminUser();
 
  
  setLoading(true);
  getDashboardAnalytics();
  setLoading(false);
}, []);





if (loading) return <Spinner />;

  return (
    <div className="Dashboard">

    
    <div className="cc-flex-row dashboard-header-bar">

    <div className="dashboard-card dashboard-card-1" style={ {backgroundColor: '#D01E1B', marginRight: '20px'}}>
        <h3>Total Practitioners</h3>
        <h3>{analytics}</h3>
    </div>

    <div className="dashboard-card" style={{marginRight: '20px'}}>
        <h3>Toronto</h3>
        <strong>Total: 0</strong>
        <strong>Law Firms: 0</strong>
        <strong>Sole Practitioners: 0</strong>
    </div>

    <div className="dashboard-card">
        <h3>Mississauga</h3>
        <strong>Total: 0</strong>
        <strong>Law Firms: 0</strong>
        <strong>Sole Practitioners: 0</strong>
    </div>

    </div>

    </div>
  );
};


Dashboard.propTypes = {
  logoutUser: PropTypes.func.isRequired,
};



const mapStateToProps = state => ({
  auth: state.auth,
});



export default withRouter(connect(mapStateToProps, { logoutUser })(Dashboard));


