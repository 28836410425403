import React from 'react';

import spinner from './spinner.gif';

const Spinner = () => (
  <div style={{
    height: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center',
  }}
  >
    <img
      src={spinner}
      style={{ width: '60px', margin: 'auto', display: 'block' }}
      alt="Loading..."
    />
  </div>
);

export default Spinner;
