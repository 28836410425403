import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './TextField.css';


class TextField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      name: '',
      placeholder: '',
      value: '',
      label: '',
      error: [],
      info: '',
      type: '',

    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }


  disableInput() {
    const { error, info, type, className, onChange, placeholder, name, value } = this.props;

    return (
      <div className="text-field-group">
        <span className="lw-portal-text-input-title">{this.props.title}</span>
        <input
          className={className + ' disabled'}
          type={type}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          autoComplete="off"
          disabled
        />

        {info && <small className="lw-portal-text-input-info">{info}</small>}

      </div>
    );
  }

  
  render() {
    if (this.props.disableInput){
      return this.disableInput();
    }


    const { error, info, type, className, onChange, placeholder, name, value } = this.props;

    return (
      <div className="text-field-group">
        <span className="lw-portal-text-input-title">{this.props.title}</span>
        <input
          type={type}
          className={classnames(className ? className : "lw-portal-text-input", { 'is-invalid': error })}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          autoComplete="off"
        />

        {info && <small className="lw-portal-text-input-info">{info}</small>}
        {error && <div className="invalid-feedback">{error}</div>}

      </div>
    );
  }
}


TextField.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  info: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};


export default TextField;
